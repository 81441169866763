import React, { useRef } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import Body from "components/Body";
import Icon from "components/Icon";
import classNames from "lib/classNames";
import useIsInViewport from "hook/useIsInViewport";
import useTranslation from "next-translate/useTranslation";
import NextLink, { removeLangFromHref } from "components/NextLink";

export default function ReinsuranceBlock({ data }) {
  const { t, lang } = useTranslation("common");
  const router = useRouter();
  const ref = useRef(null);
  const isInViewport = useIsInViewport(ref);
  const transitionDuration = 0.5;
  const transitionDelay = transitionDuration * 0.75;

  return (
    <section className="bg-primary py-28">
      <div ref={ref} className="container px-4 mx-auto">
        <h2 className="sr-only">{data.info}</h2>

        {data.body && (
          <div
            className="prose transition-all"
            style={{
              opacity: isInViewport ? 100 : 0,
              transform: `translateY(${isInViewport ? 0 : "-1.5rem"})`,
              transitionDuration: `${transitionDuration}s`,
              transitionDelay: `${transitionDelay}s`,
            }}
          >
            <Body value={data.body.processed} />
          </div>
        )}

        {data.field_paragraphs.length > 0 && (
          <ul className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 2xl:gap-8">
            {data.field_paragraphs.map((paragraph, index) => {
              const hasLink = paragraph.field_link;
              const thisTransitionDelay = transitionDelay * index;

              return (
                <li
                  key={paragraph.id}
                  onClick={() =>
                    hasLink &&
                    router.push(
                      removeLangFromHref(paragraph.field_link.url, lang)
                    )
                  }
                >
                  <div
                    className={classNames(
                      "group relative px-2 py-4 text-white",
                      hasLink && "cursor-pointer"
                    )}
                  >
                    <div
                      className="absolute left-2 right-full inset-y-1/2 w-[72px] h-[72px] bg-primary-dark rounded-lg group-hover:left-0 -translate-y-1/2 group-hover:h-full group-hover:w-full transition-all duration-300"
                      aria-hidden="true"
                    />
                    <div className="relative flex flex-row sm:items-center gap-4 sm:gap-8">
                      <div className="flex-initial leading-[0]">
                        <div
                          className="inline-block p-5 bg-primary-dark rounded-md transition-all"
                          style={{
                            opacity: isInViewport ? 100 : 0,
                            transform: `scale(${isInViewport ? 1 : 0.75})`,
                            transitionDuration: `${transitionDuration}s`,
                            transitionDelay: `${thisTransitionDelay}s`,
                          }}
                        >
                          <Icon
                            name={paragraph.field_icon.field_component}
                            className="h-8 w-8"
                          />
                        </div>
                      </div>
                      <div className="flex-1">
                        <div
                          className="font-important font-medium text-xl transition-all"
                          style={{
                            opacity: isInViewport ? 100 : 0,
                            transform: `translateX(${
                              isInViewport ? 0 : "-1.5rem"
                            })`,
                            transitionDuration: `${transitionDuration}s`,
                            transitionDelay: `${thisTransitionDelay + 0.2}s`,
                          }}
                        >
                          {paragraph.field_title}
                        </div>
                        <div
                          className="font-important text-secondary-light transition-all"
                          style={{
                            opacity: isInViewport ? 100 : 0,
                            transform: `translateX(${
                              isInViewport ? 0 : "-1.5rem"
                            })`,
                            transitionDuration: `${transitionDuration}s`,
                            transitionDelay: `${thisTransitionDelay + 0.4}s`,
                          }}
                        >
                          <Body value={paragraph.field_text.processed} />
                        </div>
                        {hasLink && (
                          <NextLink
                            href={paragraph.field_link.url}
                            className="sr-only"
                          >
                            {t("utils.more_link")}
                          </NextLink>
                        )}
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </section>
  );
}
